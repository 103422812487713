import { Box, Typography, Button, Avatar } from "@mui/material";
import { useContext } from "react";
import _ from "lodash";

import { UiContext } from "../../../store/UiContext";
import { CartModal } from "../CartModal";
import { ProductTypeEnum } from "../../../config";
import { User } from "../../types";

export const StickyProductSummary = ({
  productInfo,
  retailer,
  consumer,
  jwtToken,
}: {
  productInfo: any;
  retailer: User;
  consumer: User;
  jwtToken: string;
}) => {
  const { setShowCartModal } = useContext(UiContext);

  const buyProduct = () => {
    if (
      _.get(productInfo, "productType") ===
      ProductTypeEnum.EXTERNAL_PRODUCT_TYPE
    ) {
      window.open(productInfo?.externalProductLink, "_blank");
      return;
    }
    setShowCartModal(true);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Avatar
          variant="square"
          src={_.get(productInfo, "imagePath")}
          sx={{
            width: 60,
            height: 45,
            borderRadius: 1,
          }}
        />
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "bold",
              fontSize: "16px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "30vw",
            }}
          >
            {_.truncate(_.get(productInfo, "name"), {
              length: 15,
            })}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "purple",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            ${_.get(productInfo, "price")}
          </Typography>
        </Box>
      </Box>
      <Button
        onClick={buyProduct}
        variant="contained"
        sx={{
          bgcolor: "#6200ee",
          color: "white",
          borderRadius: "8px",
          textTransform: "none",
          px: 4,
          py: 1,
          "&:hover": {
            bgcolor: "#5000c9",
          },
        }}
      >
        Buy Now
      </Button>
      <CartModal
        product={productInfo}
        retailer={retailer}
        consumer={consumer}
        jwtToken={jwtToken}
      />
    </>
  );
};
