import { Box, Typography, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";

import { StreamStatusEnum } from "../../../config";

const StreamerInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  color: "#fff",
}));

const LiveStatus = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(0.5),
  color: "#fff",
  // backgroundColor: "rgba(255, 0, 0, 0.8)",
  padding: theme.spacing(0.5, 1),
  borderRadius: "4px",
  fontSize: "12px",
  fontWeight: "bold",
}));

export const RetailerDetails = ({
  avatarUrl,
  storeName,
  streamStatus,
}: {
  avatarUrl: string;
  storeName: string;
  streamStatus: number;
}) => {
  const getStreamStatusText = () => {
    switch (streamStatus) {
      case StreamStatusEnum.PENDING:
        return "Scheduled";
      case StreamStatusEnum.IN_PROGRESS:
        return "Live";
      case StreamStatusEnum.CLOSED:
        return "Recorded";
      default:
        return "";
    }
  };
  return (
    <>
      <StreamerInfo>
        <Avatar src={avatarUrl} sx={{ width: 32, height: 32 }} />
        <Box>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: "bold",
              lineHeight: 1,
            }}
          >
            {storeName}
          </Typography>
        </Box>
      </StreamerInfo>
      <LiveStatus>
        {streamStatus === StreamStatusEnum.IN_PROGRESS ? (
          <svg
            width="14"
            height="14"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.80078 15.1992C6.26172 16.6315 7.99479 17.3477 10 17.3477C12.0052 17.3477 13.724 16.6315 15.1562 15.1992C16.6172 13.7383 17.3477 12.0052 17.3477 10C17.3477 7.99479 16.6172 6.27604 15.1562 4.84375C13.724 3.38281 12.0052 2.65234 10 2.65234C7.99479 2.65234 6.26172 3.38281 4.80078 4.84375C3.36849 6.27604 2.65234 7.99479 2.65234 10C2.65234 12.0052 3.36849 13.7383 4.80078 15.1992ZM3.51172 3.55469C5.31641 1.75 7.47917 0.847656 10 0.847656C12.5208 0.847656 14.6693 1.75 16.4453 3.55469C18.25 5.33073 19.1523 7.47917 19.1523 10C19.1523 12.5208 18.25 14.6836 16.4453 16.4883C14.6693 18.2643 12.5208 19.1523 10 19.1523C7.47917 19.1523 5.31641 18.2643 3.51172 16.4883C1.73568 14.6836 0.847656 12.5208 0.847656 10C0.847656 7.47917 1.73568 5.33073 3.51172 3.55469ZM6.73438 6.77734C7.65104 5.86068 8.73958 5.40234 10 5.40234C11.2604 5.40234 12.3346 5.86068 13.2227 6.77734C14.1393 7.66536 14.5977 8.73958 14.5977 10C14.5977 11.2604 14.1393 12.349 13.2227 13.2656C12.3346 14.1536 11.2604 14.5977 10 14.5977C8.73958 14.5977 7.65104 14.1536 6.73438 13.2656C5.84635 12.349 5.40234 11.2604 5.40234 10C5.40234 8.73958 5.84635 7.66536 6.73438 6.77734Z"
              fill="#DC0B0B"
            />
          </svg>
        ) : null}

        {getStreamStatusText()}
      </LiveStatus>
    </>
  );
};
