import moment from "moment";

import { BASE_URL } from "../config";

export const formatDate = (inputDate: string) => {
  let formattedDate = "";
  try {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    formattedDate = months[month] + " " + day + ", " + year;
    return formattedDate;
  } catch (error) {
    console.log(error);
  }
  return "";
};

export const fetchStoreInfo = async (retailerDomain: string) => {
  const requestOptions = {
    method: "GET",
  };

  const response = await fetch(
    `${BASE_URL}/shops/${retailerDomain}/retailer-details`,
    requestOptions,
  );
  const storeInfo = await response.json();
  return storeInfo;
};

export const createOrder = async (orderData: { [key: string]: any }) => {
  // const data = {
  //   addressLine1: "Uttara",
  //   cityOrTown: "Dhaka",
  //   currency: "USD",
  //   deliveryFee: 0,
  //   domain: "zilani.com",
  //   email: "prolificos@gmail.com",
  //   firstName: "Israr",
  //   lastName: "Hamid",
  //   phoneNumber: "+8801525252",
  //   quantity: 1,
  //   state: "Dhaka",
  //   type: "SHIPMENT",
  //   variationId: "BNLJH67QHKCABD6SYJKHTMGT",
  //   zipCode: "12352",
  // };

  const response = await fetch(`${BASE_URL}/orders`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(orderData),
  });

  const order = await response.json();

  return order;
};

export const makePayment = async (paymentData: any) => {
  const response = await fetch(`${BASE_URL}/payments`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(paymentData),
  });

  const paymentInfo = await response.json();

  return paymentInfo;
};

export const startStream = async (
  userId: string,
  scheduleId: string,
  jwtToken: string,
) => {
  await fetch(
    `${BASE_URL}/retailer/${userId}/schedule/${scheduleId}/start-streaming`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        startTime: moment(),
      }),
    },
  );
};

export const stopStream = async (userId: string, scheduleId: string) => {
  await fetch(
    `${BASE_URL}/retailer/${userId}/schedule/${scheduleId}/stop-streaming`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        endTime: moment(),
      }),
    },
  );
};

export const getUserDetails = async (userId: string, jwtToken: string) => {
  const response = await fetch(`${BASE_URL}/users/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
  });

  const userInfo = await response.json();
  return userInfo;
};

export const getStoreDeliveryCharge = async (storeDomain: string) => {
  const response = await fetch(
    `${BASE_URL}/shops/${storeDomain}/service-charges/delivery-fee`,
    {
      method: "GET",
    },
  );
  const deliveryCharge = await response.json();
  return deliveryCharge || 0;
};

export const getItemExpandedDetailsById = async (itemId: string) => {
  const response = await fetch(`${BASE_URL}/items/${itemId}/expanded-details`);
  const expandedProductDetails = await response.json();
  return expandedProductDetails;
};

export const getItemsByStreamId = async (
  userId: string,
  scheduleId: string,
  jwtToken: string,
) => {
  const response = await fetch(
    `${BASE_URL}/retailer/${userId}/schedule/${scheduleId}/items`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    },
  );
  const products = await response.json();
  return products;
};

export const getUserInfoByJWT = async (jwtToken: string) => {
  let response = await fetch(`${BASE_URL}/users/me`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
  });
  const authenticatedUser = await response.json();
  return authenticatedUser;
};

export const getItemDetailsById = async (
  itemId: string,
  storeDomain: string,
) => {
  let productInfo: any = {};
  try {
    let response = await fetch(`${BASE_URL}/items/${itemId}/details`);
    productInfo = await response.json();
    const taxId = (productInfo as any)?.tax_ids[0];
    response = await fetch(
      `${BASE_URL}/items/tax-details/${taxId}/domain/${storeDomain}`,
    );
    const taxDetails = await response.json();
    productInfo.taxDetails = taxDetails.tax_details;
    return productInfo;
  } catch (error) {
    console.log("Error in fetching get item details by id");
    console.log(error);
  } finally {
    return productInfo;
  }
};

export const subscribeExclusiveSession = async ({
  subscriptionData,
  sessionId,
  jwtToken,
}: {
  subscriptionData: any;
  sessionId: string;
  jwtToken: string;
}) => {
  try {
    const response = await fetch(
      `${BASE_URL}/schedule/exclusive/${sessionId}/subscribe`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(subscriptionData),
      },
    );
    const subscriptionInfo = await response.json();
    return subscriptionInfo;
  } catch (error) {
    console.log(error);
  }
};

export const getScheduleById = async (scheduleId: string, jwtToken: string) => {
  let scheduleInfo: any = {};
  try {
    let response = await fetch(`${BASE_URL}/schedule/${scheduleId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    scheduleInfo = await response.json();
    return scheduleInfo;
  } catch (error) {
    console.log("Error in fetching get item details by id");
    console.log(error);
  }
};

export const getProjectById = async (projectId: string, jwtToken: string) => {
  let scheduleInfo: any = {};
  try {
    let response = await fetch(`${BASE_URL}/project/${projectId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    scheduleInfo = await response.json();
    return scheduleInfo;
  } catch (error) {
    console.log("Error in fetching get project details by id");
    console.log(error);
  }
};

export const makeProjectPayment = async ({
  projectId,
  paymentToken,
  jwtToken,
}: {
  projectId: string;
  paymentToken: string;
  jwtToken: string;
}) => {
  try {
    const response = await fetch(`${BASE_URL}/project/payment/${projectId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        paymentToken,
      }),
    });
    const subscriptionInfo = await response.json();
    return subscriptionInfo;
  } catch (error) {
    console.log(error);
  }
};

export const getCommentsByStreamId = async (streamId: string) => {
  const response = await fetch(
    `${BASE_URL}/users/schedule/${streamId}/comments`,
    {
      method: "GET",
    },
  );
  const data = await response.json();
  return data;
};

export const createCommentAPI = async ({
  content,
  userId,
  streamId,
}: {
  content: string;
  userId: string;
  streamId: string;
}) => {
  const response = await fetch(
    `${BASE_URL}/users/${userId}/schedule/${streamId}/comment`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        content,
      }),
    },
  );
  const newComment = await response.json();
  return newComment;
};

export const getStreamName = ({
  domain = "",
  sessionId = "",
}: {
  domain: string;
  sessionId: string;
}) => {
  // trim white space
  let streamName = domain.replace(/\s/g, "");
  // remove dot
  streamName = streamName.replace(/\./g, "");
  // concat the session id
  streamName = `${streamName}${sessionId}`;
  return streamName;
};
