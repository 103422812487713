import { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import Player from "video.js/dist/types/player";
import "video.js/dist/video-js.css";

export const OfflineVideoPlayer = ({ streamUrl }: { streamUrl: string }) => {
  const videoNode = useRef(null);
  const [player, setPlayer] = useState<Player>();
  useEffect(() => {
    if (videoNode.current && streamUrl) {
      console.log(`streamUrl: ${streamUrl}`);
      const _player = videojs(videoNode.current, {
        fill: true,
        fluid: true,
        autoplay: true,
        controls: true,
        preload: "auto",
        inactivityTimeout: 0,
        sources: [
          {
            src: streamUrl,
            type: "application/x-mpegURL",
          },
        ],
      });
      setPlayer(_player);
      return () => {
        if (player) {
          player.dispose();
        }
      };
    }
  }, [streamUrl]);

  return (
    <video
      autoPlay
      ref={videoNode}
      controls
      muted
      playsInline
      style={{
        width: "100%",
      }}
      className="video-js"
    ></video>
  );
};
