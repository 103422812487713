import _ from "lodash";

export enum StreamStatusEnum {
  PENDING = 0,
  IN_PROGRESS = 1,
  CLOSED = 2,
}

export enum StreamTypeEnum {
  PUBLISH = "PUBLISH",
  SUBSCRIBE = "SUBSCRIBE",
  RECORDED = "RECORDED",
  PRERECORDED = "PRERECORDED",
}

export enum ProductTypeEnum {
  EXTERNAL_PRODUCT_TYPE = "EXTERNAL_PRODUCT_TYPE",
  SQUAREUP_PRODUCT_TYPE = "SQUAREUP_PRODUCT_TYPE",
}

const developmentConfig = {
  BASE_URL: "http://localhost:4430/api",
  APP_ID: "sandbox-sq0idb-rLZaTxvDSEn0NllIzsxViA",
  DEEP_LINK_URL: "exp://10.5.5.167:19000",
  WEBVIEW: "staging.d2u5s7pibvzjwf.amplifyapp.com",
};

const stagingConfig = {
  BASE_URL: "https://staging.marketplace0.live/api",
  APP_ID: "sandbox-sq0idb-rLZaTxvDSEn0NllIzsxViA",
  DEEP_LINK_URL: "marketplace0://",
  WEBVIEW: "staging.d2u5s7pibvzjwf.amplifyapp.com",
};

const productionConfig = {
  BASE_URL: "https://marketplace0.live/api",
  APP_ID: "sq0idp-nr1rafGiKrqzklYxOFnfGA",
  DEEP_LINK_URL: "marketplace0://",
  WEBVIEW: "web.marketplace0.live",
};

const config = productionConfig;

export const BASE_URL = _.get(config, "BASE_URL");
export const APP_ID = _.get(config, "APP_ID");
export const DEEP_LINK_URL = _.get(config, "DEEP_LINK_URL");
export const WEBVIEW = _.get(config, "WEBVIEW");
