import { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import { Grid, IconButton } from "@mui/material";
import _ from "lodash";

import { BASE_URL } from "../../config";
import { CommentContext } from "../../store/CommentContext";

export const CommentsInput = ({ disabled = false }: { disabled?: boolean }) => {
  const [content, setContent] = useState("");
  const { setCommentList } = useContext(CommentContext);
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const streamId = searchParams.get("streamId");
  const userId = searchParams.get("userId");

  const createComment = async () => {
    // make the api call
    if (!userId) {
      throw Error("No User found");
    }

    try {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          content,
        }),
      };

      const response = await fetch(
        `${BASE_URL}/users/${userId}/schedule/${streamId}/comment`,
        requestOptions
      );
      const comments = await response.json();
      setCommentList(comments);
    } catch (error) {
      console.log("Error on making the comment");
    } finally {
      setLoading(false);
    }

    // clear the content from the input box
    setContent("");
    // fetch the comments api and update the comment list
  };

  return (
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item md={10} xs={10}>
        <input
          placeholder="Type your message"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          style={{
            borderRadius: "19px",
            height: "32px",
            border: "none",
            background: "rgba(239, 239, 239, 0.55)",
            padding: "0 0 0 12px",
            width: "100%",
          }}
          disabled={disabled || loading}
        />
      </Grid>
      <Grid
        item
        md={2}
        xs={2}
        style={{
          marginTop: "-4px",
        }}
      >
        <IconButton
          color="secondary"
          onClick={createComment}
          aria-label="add to shopping cart"
          disabled={disabled || loading || !_.size(content)}
        >
          <SendIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};
