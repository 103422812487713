import { useContext, useState } from "react";
import { Box, IconButton, TextField, Avatar } from "@mui/material";
import { Send as SendIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import { CommentContext } from "../../../store/CommentContext";
import { createCommentAPI, getCommentsByStreamId } from "../../utils";

const MessageInput = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#f5f5f5",
    borderRadius: "24px",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "12px 16px",
  },
});

const SendButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "#6200ee", // Purple color as shown in the image
  color: "white",
  width: "40px",
  height: "40px",
  "&:hover": {
    backgroundColor: "#5000c9",
  },
}));

export const CommentInput = ({
  streamId,
  userId,
  userImage,
}: {
  streamId: string;
  userId: string;
  userImage: string;
}) => {
  const [content, setContent] = useState("");
  const { setCommentList } = useContext(CommentContext);
  const [loading, setLoading] = useState(false);

  const createComment = async () => {
    setLoading(true);
    await createCommentAPI({
      content,
      userId,
      streamId,
    });
    const res = await getCommentsByStreamId(streamId);
    setCommentList(res);
    setLoading(false);
    setContent("");
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        width: "100%",
      }}
    >
      <Avatar
        src={userImage}
        sx={{
          width: 36,
          height: 36,
        }}
      />
      <MessageInput
        fullWidth
        placeholder="Type your message"
        variant="outlined"
        size="small"
        sx={{
          flex: 1,
        }}
        value={content}
        onChange={e => setContent(e.target.value)}
      />
      <SendButton onClick={createComment} disabled={loading}>
        <SendIcon fontSize="small" />
      </SendButton>
    </Box>
  );
};
