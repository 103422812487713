import { CSSProperties } from "react";

export const commentContainerStyle = {
  overflow: "auto",
  position: "absolute",
  maxHeight: "50%",
  maxWidth: "80%",
  bottom: "56px",
  left: "16px",
} as CSSProperties;
