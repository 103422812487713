import { useEffect, useState } from "react";
import _ from "lodash";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";

import {
  getScheduleById,
  getUserDetails,
  subscribeExclusiveSession,
} from "../shared/utils";
import { SquareUpPayment } from "../shared/components/SquareUpPayment";

declare global {
  interface Window {
    Square: any;
  }
}

export const PaymentStream = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [consumerInfo, setConsumerInfo] = useState<any>();
  const [retailerInfo, setRetailerInfo] = useState<any>();
  const [sessionInfo, setSessionInfo] = useState<any>();

  const consumerId = searchParams.get("consumerId") || "";
  const sessionId = searchParams.get("sessionId") || "";
  const retailerId = searchParams.get("retailerId") || "";
  const jwtToken = searchParams.get("jwtToken") || "";

  // from search param
  // http://localhost:3000/payment?consumerId=60fffa04de296b11e8307b5d&jwtToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwZmZmYTA0ZGUyOTZiMTFlODMwN2I1ZCIsIm5hbWUiOiJaaWxhbmkgUmV0YWlsZXIiLCJlbWFpbCI6InppbGFuaTE2QGdtYWlsLmNvbSIsImlhdCI6MTcwNDE3MDI1MiwiZXhwIjoxNzA0MTczODUyfQ.gv9nt6qssA7ZLOlpDWfw9CMWKwAec-Arrx6hCtTYHlA&retailerId=60fffa04de296b11e8307b5d&sessionId=659260341a19eaedb76e8491
  // or
  // http://localhost:3000/payment?consumerId=60ed4eb22b43b23c90d1d8b1&jwtToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwZWQ0ZWIyMmI0M2IyM2M5MGQxZDhiMSIsIm5hbWUiOiJJc3JhciBIYW1pZCIsImVtYWlsIjoiY3JlYXRvckBtYWlsLmNvbSIsImlhdCI6MTcxMjU1OTE0NCwiZXhwIjoxNzQ0MDk1MTQ0fQ._CGhQXC-j2buR6ddLcAA7HeTFoY-p9jN1F_jYYlh0f4&retailerId=60fffa04de296b11e8307b5d&sessionId=66139390a7b7f2860236cd65

  const fetchData = async () => {
    const consumer = await getUserDetails(consumerId, jwtToken);
    const retailer = await getUserDetails(retailerId, jwtToken);
    // TODO: do not bring the schedule url, until the payment is done
    const schedule = await getScheduleById(sessionId, jwtToken);

    setConsumerInfo(consumer);
    setRetailerInfo(retailer);
    setSessionInfo(schedule);
  };

  useEffect(() => {
    if (!consumerId || !sessionId || !retailerId || !jwtToken) {
      return;
    }
    fetchData();
  }, [consumerId, sessionId, retailerId, jwtToken]);

  const getStreamName = ({
    domain = "",
    sessionId = "",
  }: {
    domain: string;
    sessionId: string;
  }) => {
    // trim white space
    let streamName = domain.replace(/\s/g, "");
    // remove dot
    streamName = streamName.replace(/\./g, "");
    // concat the session id
    streamName = `${streamName}${sessionId}`;
    return streamName;
  };

  const moveToPlayer = async () => {
    const streamName = getStreamName({
      domain: retailerInfo.domain || "",
      sessionId,
    });

    const isPending = sessionInfo.status === 0;

    const queryParams = queryString.stringify({
      streamName,
      streamId: sessionInfo.id || sessionInfo._id,
      userId: consumerInfo.id || consumerInfo._id,
      streamTitle: sessionInfo.title,
      retailerDomain: retailerInfo.domain,
      startTime: sessionInfo.startTime,
      jwtToken,
      showPreview: isPending,
      showRecorded: !isPending,
      advertizeVideoUrl: sessionInfo.advertizeVideoUrl,
      streamThumbnailUrl: sessionInfo.streamThumbnailUrl,
      status: sessionInfo.status,
    });

    navigate(`/subscribe?${queryParams}`);
  };

  const handlePayment = async (paymentToken: string) => {
    const subscriptionData = {
      source_id: paymentToken,
      stream_status: sessionInfo.status,
      user_id: consumerId,
      amount: sessionInfo.amount,
      firstName: consumerInfo.firstName,
      lastName: consumerInfo.lastName,
      phoneNumber: consumerInfo.phoneNumber,
      state: consumerInfo.state,
      addressLine1: consumerInfo.addressLine1,
      addressLine2: consumerInfo.addressLine2,
      cityOrTown: consumerInfo.cityOrTown,
      zipCode: consumerInfo.zipCode,
      email: consumerInfo.email,
    };

    const subscription = await subscribeExclusiveSession({
      subscriptionData,
      sessionId,
      jwtToken,
    });

    return (
      !_.isEmpty(subscription) &&
      !_.isEmpty((subscription as unknown as any)?.subscriptionId)
    );
  };

  const cb = async (paymentToken: string) => {
    try {
      const isPaymentDone = await handlePayment(paymentToken);
      if (isPaymentDone) {
        await moveToPlayer();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <SquareUpPayment
      buyerInfo={consumerInfo}
      sellerInfo={retailerInfo}
      productInfo={{
        title: sessionInfo?.title,
        amount: sessionInfo?.amount / 100,
        imageUrl: sessionInfo?.streamThumbnailUrl,
      }}
      callBack={cb}
    />
  );
};
