import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

// import { User } from "../types";
// import { fetchStoreInfo, formatDate } from "../utils";
// import { BASE_URL } from "../../config";

export const StoreDetails = () => {
  const [searchParams] = useSearchParams();
  // const [storeInfo, setStoreInfo] = useState<User>();
  const [startedAt, setStartedAt] = useState("");

  const streamTitle = searchParams.get("streamTitle");
  // const retailerDomain = searchParams.get("retailerDomain");
  const startTime = searchParams.get("startTime");
  const scheduleStatus = parseInt(searchParams.get("status") || "");

  const getTimingText = () => {
    switch (scheduleStatus) {
      case 0:
        return `Scheduled ${startedAt}`;
      case 1:
        return "Live";
      case 2:
        return `Recorded ${startedAt}`;
      default:
        return `Scheduled ${startedAt}`;
    }
  };

  // const getStore = async () => {
  //   const res = (await fetchStoreInfo(retailerDomain || "")) as User;
  //   setStoreInfo(res);
  // };

  // useEffect(() => {
  //   if (!retailerDomain) {
  //     return;
  //   }

  //   getStore();
  // }, [retailerDomain]);

  useEffect(() => {
    if (!startTime) {
      return;
    }
    setStartedAt(moment(startTime).format("MMM DD, YYYY HH:MM A"));
  }, [startTime]);

  return (
    <>
      <div
        style={{
          color: "#2E2E2E",
          fontSize: "19px",
          fontWeight: 500,
          textAlign: "left",
        }}
      >
        {streamTitle}
      </div>
      <div
        style={{
          color: "#7A7A7A",
          fontSize: "12px",
          fontWeight: 500,
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        {getTimingText()}
      </div>
    </>
  );
};
