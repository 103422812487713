import { Box, Typography, Button } from "@mui/material";
import { VolumeUp as VolumeUpIcon } from "@mui/icons-material";
import _ from "lodash";
import "video.js/dist/video-js.css";

import { CommentsContainer } from "../../shared/components/Stream/CommentsContainer";
import { StickyProductSummary } from "../../shared/components/Stream/StickyProductSummary";
import { StreamSummary } from "../../shared/components/Stream/StreamSummary";
import { RetailerDetails } from "../../shared/components/Stream/RetailerDetails";
import { CommentInput } from "../../shared/components/Stream/CommentInput";
import { ProductDetails } from "../../shared/components/Stream/ProductDetails";
import {
  PlayerWrapper,
  VideoContainer,
  TopSection,
  BottomSection,
  VideoOverlay,
  VideoPlayer,
  StickyBottom,
  UnmutePrompt,
  MessageInputContainer,
  CommentsOverlay,
  CommentsScrollContainer,
} from "./ui";
import {
  useMarketplaceStream,
  usePreRecordedStream,
  usePublishStream,
  useRecordedStream,
  useSubscribeStream,
} from "./hooks/useMarketplaceStream";
import { StreamStatusEnum, StreamTypeEnum } from "../../config";
import { Stream, User } from "../../shared/types";

const MyUnMutedPrompt = ({ handleUnmute }: { handleUnmute: () => void }) => {
  return (
    <UnmutePrompt>
      <VolumeUpIcon sx={{ fontSize: 40 }} />
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        Tap to unmute
      </Typography>
      <Button
        variant="contained"
        onClick={handleUnmute}
        sx={{
          backgroundColor: "white",
          color: "black",
          "&:hover": {
            backgroundColor: "rgba(255,255,255,0.9)",
          },
        }}
      >
        Enable Sound
      </Button>
    </UnmutePrompt>
  );
};

const SubscribeLiveStream = ({
  schedule,
  retailer,
}: {
  schedule: Stream;
  retailer: User;
}) => {
  const { isMuted, showUnmutePrompt, handleUnmute } = useSubscribeStream(
    schedule,
    retailer,
  );

  return (
    <div style={{ position: "relative" }}>
      <video
        id="red5pro-subscriber"
        autoPlay
        playsInline
        webkit-playsinline="true"
        x5-playsinline="true"
        controls={false}
        muted={isMuted}
      ></video>

      {showUnmutePrompt && <MyUnMutedPrompt handleUnmute={handleUnmute} />}
    </div>
  );
};

const PublishLiveStream = ({
  schedule,
  retailer,
}: {
  schedule: Stream;
  retailer: User;
}) => {
  usePublishStream(schedule, retailer);
  return (
    <video
      id="red5pro-publisher"
      autoPlay
      playsInline
      webkit-playsinline="true"
      x5-playsinline="true"
      controls={false}
    ></video>
  );
};

const PreRecordedStream = ({ schedule }: { schedule: Stream }) => {
  const { videoRef, showUnmutePrompt, handleUnmute } = usePreRecordedStream();
  return (
    <>
      <VideoPlayer
        ref={videoRef}
        loop
        playsInline
        autoPlay
        webkit-playsinline="true"
        x5-playsinline="true"
        controls={false}
      >
        <source src={_.get(schedule, "advertizeVideoUrl")} type="video/mp4" />
      </VideoPlayer>

      {showUnmutePrompt && (
        <UnmutePrompt>
          <VolumeUpIcon sx={{ fontSize: 40 }} />
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Tap to unmute
          </Typography>
          <Button
            variant="contained"
            onClick={handleUnmute}
            sx={{
              backgroundColor: "white",
              color: "black",
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.9)",
              },
            }}
          >
            Enable Sound
          </Button>
        </UnmutePrompt>
      )}
    </>
  );
};

const RecordedStream = ({
  schedule,
  retailer,
}: {
  schedule: Stream;
  retailer: User;
}) => {
  const { videoRef, showUnmutePrompt, handleUnmute } = useRecordedStream(
    schedule,
    retailer,
  );

  return (
    <>
      <video
        autoPlay
        ref={videoRef}
        controls
        playsInline
        style={{
          width: "100%",
        }}
        className="video-js"
      ></video>
      {showUnmutePrompt && <MyUnMutedPrompt handleUnmute={handleUnmute} />}
    </>
  );
};

export const MarketplaceStream = () => {
  const {
    schedule,
    retailer,
    avatarUrl,
    authenticatedUser,
    productInfo,
    streamType,
    handleVideoTouch,
    scrollToProductDetails,
    productDetailsRef,
    handleCommentScroller,
    jwtToken,
  } = useMarketplaceStream();

  // Handle unmute

  const renderScheduledThumbnail = () => {
    return (
      <img
        src={_.get(schedule, "streamThumbnailUrl", "")}
        style={{ width: "100%" }}
        alt="stream-thumbnail"
      />
    );
  };

  const renderStream = () => {
    if (_.isEmpty(schedule) || _.isEmpty(retailer)) {
      return;
    }

    // Preview
    if (_.get(schedule, "status") === StreamStatusEnum.PENDING) {
      return renderScheduledThumbnail();
    }

    // handle PRE Recorded live stream
    if (
      streamType === StreamTypeEnum.PRERECORDED
      //  !_.isEmpty(_.get(schedule, "advertizeVideoUrl"))
    ) {
      return <PreRecordedStream schedule={schedule} />;
    }

    if (streamType === StreamTypeEnum.RECORDED) {
      return <RecordedStream schedule={schedule} retailer={retailer} />;
    }

    if (streamType === StreamTypeEnum.PUBLISH) {
      return <PublishLiveStream schedule={schedule} retailer={retailer} />;
    }

    if (streamType === StreamTypeEnum.SUBSCRIBE) {
      return <SubscribeLiveStream schedule={schedule} retailer={retailer} />;
    }
  };

  return (
    <Box sx={{ pb: 8 }}>
      <PlayerWrapper>
        <VideoContainer onTouchStart={handleVideoTouch}>
          {renderStream()}
          <VideoOverlay>
            <TopSection>
              <RetailerDetails
                avatarUrl={avatarUrl}
                storeName={_.get(retailer, "storeName", "")}
                streamStatus={_.get(schedule, "status")!}
              />
            </TopSection>
          </VideoOverlay>
          <CommentsOverlay>
            <CommentsScrollContainer onWheel={handleCommentScroller}>
              <CommentsContainer streamId={_.get(schedule, "id", "")} />
            </CommentsScrollContainer>
          </CommentsOverlay>

          <BottomSection>
            <StreamSummary
              onViewProduct={scrollToProductDetails}
              streamName={_.get(schedule, "title", "")}
              scheduleStartTime={_.get(schedule, "startTime", "")}
              streamUrl={_.get(schedule, "streamThumbnailUrl", "")}
            />
          </BottomSection>
        </VideoContainer>
        <MessageInputContainer>
          <CommentInput
            userId={_.get(authenticatedUser, "id", "")}
            streamId={_.get(schedule, "id", "")}
            userImage={
              _.get(schedule, "profilePictureUrl", "") ??
              _.get(schedule, "storeBannerUrl", "")
            }
          />
        </MessageInputContainer>

        <Box ref={productDetailsRef}>
          <ProductDetails productInfo={productInfo} />
        </Box>
      </PlayerWrapper>

      <StickyBottom>
        <StickyProductSummary
          productInfo={productInfo}
          retailer={retailer!}
          consumer={authenticatedUser!}
          jwtToken={jwtToken!}
        />
      </StickyBottom>
    </Box>
  );
};
