import React, { createContext, useState } from "react";

import { User } from "../shared/types";
import { SquareProduct } from "../shared/types/SquareUpProduct";

interface UiContextProps {
  showCartModal: boolean;
  setShowCartModal: (showCartModal: boolean) => void;
  showPaymentConfirmationDialog: boolean;
  setShowPaymentConfirmationDialog: (
    showPaymentConfirmationDialog: boolean,
  ) => void;
  productInfo?: SquareProduct;
  setProductInfo: (productInfo: SquareProduct) => void;
  storeInfo?: User;
  setStoreInfo: (storeInfo: User) => void;
  productQuantity: number;
  setProductQuantity: (productQuantity: number) => void;
}

export const UiContext = createContext<UiContextProps>({
  showCartModal: false,
  setShowCartModal: (showCartModal: boolean) => {},
  showPaymentConfirmationDialog: false,
  setShowPaymentConfirmationDialog: (
    showPaymentConfirmationDialog: boolean,
  ) => {},
  productInfo: undefined,
  setProductInfo: (productInfo: SquareProduct) => {},
  storeInfo: undefined,
  setStoreInfo: (storeInfo: User | null) => {},
  productQuantity: 1,
  setProductQuantity: (productQuantity: number) => {},
});

export const UiProvider = ({ children }: { children: React.ReactNode }) => {
  const { Provider } = UiContext;
  const [showCartModal, setShowCartModal] = useState(false);
  const [showPaymentConfirmationDialog, setShowPaymentConfirmationDialog] =
    useState(false);
  const [productInfo, setProductInfo] = useState<SquareProduct>();
  const [storeInfo, setStoreInfo] = useState<User>();
  const [productQuantity, setProductQuantity] = useState(1);

  return (
    <Provider
      value={{
        showCartModal,
        setShowCartModal,
        showPaymentConfirmationDialog,
        setShowPaymentConfirmationDialog,
        productInfo,
        setProductInfo,
        storeInfo,
        setStoreInfo,
        productQuantity,
        setProductQuantity,
      }}
    >
      {children}
    </Provider>
  );
};
