import { useContext, useEffect, useRef, useState } from "react";
import { Button, Divider, Grid, LinearProgress, Snackbar } from "@mui/material";
import { TextField } from "formik-mui";
import { Formik, Form, Field } from "formik";
import { useSearchParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";

import {
  createOrder,
  getStoreDeliveryCharge,
  getUserDetails,
  makePayment,
} from "../shared/utils";
import { UiContext } from "../store/UiContext";
import { User } from "../shared/types";
import { PaymentConfirmationDialog } from "../shared/components/PaymentConfirmationDialog";
import { APP_ID } from "../config";

declare global {
  interface Window {
    Square: any;
  }
}

export const GroupHeader = ({ header }: { header: string }) => {
  return (
    <div
      style={{
        color: "#2E2E2E",
        fontSize: "17px",
        fontWeight: "500",
        padding: "16px 0 0 16px",
      }}
    >
      {header}
    </div>
  );
};

interface Values {
  addressLine1: string;
  addressLine2: string; // TODO: Do we need it
  cityOrTown: string;
  currency: "USD";
  deliveryFee: number;
  domain: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  quantity: number;
  state: string;
  type: "SHIPMENT";
  variationId: string;
  zipCode: string;
  country: string; // TODO: Do we need it
}

export const Checkout = () => {
  const ref = useRef(null);
  const [searchParams] = useSearchParams();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [userInfo, setUserInfo] = useState<User>();
  const [paymentCard, setPaymentCard] = useState<any>();

  const userId = searchParams.get("userId") || "";
  const jwtToken = searchParams.get("jwtToken") || "";
  const summaryStyle = {
    color: "#2E2E2E",
    fontSize: "15px",
  };

  const {
    productInfo,
    productQuantity,
    storeInfo,
    setShowPaymentConfirmationDialog,
  } = useContext(UiContext);

  const productUnitPrice =
    _.get(
      _.first(productInfo?.item_data?.variations),
      "item_variation_data.price_money.amount",
    ) || 0;

  const calculateTax = () => {
    if (!productInfo) {
      return 0;
    }

    const perUnitTax =
      (parseFloat(
        _.first(productInfo.taxes)?.object.tax_data.percentage || "0",
      ) *
        (productUnitPrice / 100)) /
      100;
    const totalTax = _.round(perUnitTax * productQuantity, 2);
    return totalTax;
  };

  const openSnackbar = () => {
    setShowSnackbar(true);
  };

  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  const initiatePayment = async () => {
    const payment = window?.Square?.payments(
      APP_ID,
      userInfo?.locationId,
    ) as any;
    const paymentCard = await payment?.card();
    await paymentCard.attach("#card-container");
    setPaymentCard(paymentCard);
  };

  const validateForm = (values: Partial<Values>) => {
    const errors: Partial<Values> = {};

    if (!values.firstName) {
      errors.firstName = "Required";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    }

    if (!values.addressLine1) {
      errors.addressLine1 = "Required";
    }

    if (!values.addressLine2) {
      errors.addressLine2 = "Required";
    }

    if (!values.cityOrTown) {
      errors.cityOrTown = "Required";
    }

    if (!values.zipCode) {
      errors.zipCode = "Required";
    }

    if (!values.state) {
      errors.state = "Required";
    }

    if (!values.country) {
      errors.country = "Required";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = "Required";
    }

    return errors;
  };

  const getTotalAmount = () => {
    return (
      (productUnitPrice / 100) * productQuantity + deliveryFee + calculateTax()
    );
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const res = await getUserDetails(userId, jwtToken);
      setUserInfo(res);
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    // waiting for rendering the div with id card-container
    setTimeout(() => {
      initiatePayment();
    }, 1000);
  }, []);

  useEffect(() => {
    const fetchDeliveryFees = async () => {
      const fee = await getStoreDeliveryCharge(storeInfo?.domain || "");
      setDeliveryFee(fee as unknown as number);
    };

    if (storeInfo?.domain) {
      fetchDeliveryFees();
    }
  }, [storeInfo?.domain]);

  const ActionComponent = (
    <>
      <Button color="secondary" size="small" onClick={closeSnackbar}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const OrderDivider = () => (
    <div
      style={{
        width: "100%",
        paddingTop: "4px",
        paddingBottom: "4px",
      }}
    >
      <Divider />
    </div>
  );

  return (
    <Grid container flexDirection="column">
      {userInfo ? (
        <Formik
          initialValues={{
            addressLine1: userInfo?.addressLine1,
            addressLine2: userInfo?.addressLine2,
            cityOrTown: userInfo?.cityOrTown,
            currency: "USD",
            deliveryFee,
            domain: userInfo?.domain,
            email: userInfo?.email,
            firstName: userInfo?.firstName,
            lastName: userInfo?.lastName,
            phoneNumber: userInfo?.phoneNumber,
            quantity: productQuantity,
            state: userInfo?.state,
            type: "SHIPMENT",
            zipCode: userInfo?.zipCode,
            country: userInfo?.country || "USA",
            variationId:
              // productInfo?.variations?.[0].id ||
              "",
          }}
          validate={validateForm}
          onSubmit={async (values, { setSubmitting }) => {
            if (!paymentCard) {
              return;
            }

            try {
              const tokenResult = await paymentCard.tokenize();
              if (tokenResult.status !== "OK") {
                console.log(tokenResult.status);
                return;
              }

              setSubmitting(true);
              // TODO: update amount
              const orderRes = await createOrder(values);
              const { order_id, amount_money, location_id } = orderRes;

              const paymentData = {
                amount_money,
                location_id,
                source_id: tokenResult.token,
                order_id,
              };
              const paymentInfo = await makePayment(paymentData);

              if (paymentInfo.isPaid) {
                setShowPaymentConfirmationDialog(true);
              } else {
                // TODO: alert the error
                // TODO: throw error and grab it in catch
                // In this case only once open the snackbar
                openSnackbar();
              }
            } catch (error) {
              openSnackbar();
              console.error("Error in order");
              console.error(error);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Grid container spacing={2}>
                <GroupHeader header="Shipping Information" />
                <Grid
                  item
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Field
                    component={TextField}
                    label="First Name"
                    name="firstName"
                    type="text"
                    size="small"
                    color="secondary"
                    style={{
                      width: "49%",
                    }}
                  />
                  <Field
                    component={TextField}
                    label="Last Name"
                    name="lastName"
                    type="text"
                    size="small"
                    color="secondary"
                    style={{
                      width: "49%",
                    }}
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Field
                    component={TextField}
                    label="Address Line 1"
                    name="addressLine1"
                    type="text"
                    size="small"
                    color="secondary"
                    fullWidth
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Field
                    component={TextField}
                    label="Address Line 2"
                    name="addressLine2"
                    type="text"
                    size="small"
                    color="secondary"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Field
                    component={TextField}
                    label="City"
                    name="cityOrTown"
                    type="text"
                    size="small"
                    color="secondary"
                    style={{
                      width: "49%",
                    }}
                  />
                  <Field
                    component={TextField}
                    label="Zip"
                    name="zipCode"
                    type="text"
                    size="small"
                    color="secondary"
                    style={{
                      width: "49%",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Field
                    component={TextField}
                    label="State"
                    name="state"
                    type="text"
                    size="small"
                    color="secondary"
                    style={{
                      width: "49%",
                    }}
                  />
                  <Field
                    component={TextField}
                    label="Country"
                    name="country"
                    type="text"
                    size="small"
                    color="secondary"
                    style={{
                      width: "49%",
                    }}
                  />
                </Grid>
                <GroupHeader header="Contact Information" />
                <Grid item style={{ width: "100%" }}>
                  <Field
                    component={TextField}
                    label="Email"
                    name="email"
                    type="email"
                    size="small"
                    color="secondary"
                    fullWidth
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Field
                    component={TextField}
                    label="Phone No."
                    name="phoneNumber"
                    type="text"
                    size="small"
                    color="secondary"
                    fullWidth
                  />
                </Grid>
                <GroupHeader header="Order Summary" />
                <Grid item container style={{ width: "100%" }}>
                  <Grid item container justifyContent="space-between">
                    <Grid item style={summaryStyle}>
                      {productInfo?.name}
                    </Grid>
                    <Grid item style={summaryStyle}>
                      ${" "}
                      {productUnitPrice
                        ? Math.floor(productUnitPrice / 100)
                        : "0"}
                    </Grid>
                  </Grid>
                  <OrderDivider />
                  <Grid item container justifyContent="space-between">
                    <Grid item style={summaryStyle}>
                      Subtotal
                    </Grid>
                    <Grid item style={summaryStyle}>
                      ${" "}
                      {productUnitPrice
                        ? _.round((productUnitPrice / 100) * productQuantity, 2)
                        : "N/A"}
                    </Grid>
                  </Grid>
                  <Grid item container justifyContent="space-between">
                    <Grid item style={summaryStyle}>
                      Shipping Fee
                    </Grid>
                    <Grid item style={summaryStyle}>
                      $ {deliveryFee}
                    </Grid>
                  </Grid>
                  <Grid item container justifyContent="space-between">
                    <Grid item style={summaryStyle}>
                      Tax
                    </Grid>
                    <Grid item style={summaryStyle}>
                      $ {calculateTax()}
                    </Grid>
                  </Grid>
                  <OrderDivider />
                  <Grid item container justifyContent="space-between">
                    <Grid item style={summaryStyle}>
                      Total
                    </Grid>
                    <Grid item>$ {getTotalAmount()}</Grid>
                  </Grid>
                </Grid>
                <GroupHeader header="Payment Information" />
                <Grid item style={{ width: "100%" }}>
                  <div ref={ref} id="card-container"></div>
                </Grid>
              </Grid>
              {isSubmitting && <LinearProgress color="secondary" />}
              <Button
                variant="contained"
                color="secondary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      ) : null}
      <PaymentConfirmationDialog />
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        message="Something went wrong!"
        action={ActionComponent}
      />
    </Grid>
  );
};
