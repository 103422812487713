import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";

import {
  getProjectById,
  getUserDetails,
  makeProjectPayment,
} from "../shared/utils";
import { SquareUpPayment } from "../shared/components/SquareUpPayment";

declare global {
  interface Window {
    Square: any;
  }
}

export const PaymentProject = () => {
  const [searchParams] = useSearchParams();
  const [buyerInfo, setBuyerInfo] = useState<any>();
  const [sellerInfo, setSellerInfo] = useState<any>();
  const [projectInfo, setProjectInfo] = useState<any>();

  const buyerId = searchParams.get("buyerId") || "";
  const sellerId = searchParams.get("sellerId") || "";
  const projectId = searchParams.get("projectId") || "";
  const jwtToken = searchParams.get("jwtToken") || "";

  // from search param
  // http://localhost:3000/payment/project?buyerId=60fffa04de296b11e8307b5d&jwtToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwZmZmYTA0ZGUyOTZiMTFlODMwN2I1ZCIsIm5hbWUiOiJaaWxhbmkgUmV0YWlsZXIiLCJlbWFpbCI6InJldGFpbGVyQG1haWwuY29tIiwiaWF0IjoxNzEyNTYzNzM0LCJleHAiOjE3NDQwOTk3MzR9.xoK3N5S1gB1ThfAAFex2t5iwxj6qTjG52Fh2vvXpl4M&projectId=660d3d272948ab59de954567&sellerId=60ed4eb22b43b23c90d1d8b1

  const fetchData = async () => {
    const buyer = await getUserDetails(buyerId, jwtToken);
    const seller = await getUserDetails(sellerId, jwtToken);
    const project = await getProjectById(projectId, jwtToken);

    setBuyerInfo(buyer);
    setSellerInfo(seller);
    setProjectInfo(project);
  };

  useEffect(() => {
    if (!buyerId || !sellerId || !projectId || !jwtToken) {
      return;
    }
    fetchData();
  }, [buyerId, sellerId, projectId, jwtToken]);

  const cb = async (paymentToken: string) => {
    try {
      const res = await makeProjectPayment({
        projectId,
        paymentToken,
        jwtToken,
      });
      return !!res.isSuccess;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  if (_.isEmpty(buyerInfo) || _.isEmpty(sellerInfo) || _.isEmpty(projectInfo)) {
    return <div>Loading...</div>;
  }

  return (
    <SquareUpPayment
      buyerInfo={buyerInfo}
      sellerInfo={sellerInfo}
      productInfo={{
        title: projectInfo.name,
        amount: projectInfo.price,
        imageUrl: projectInfo.thumbnail,
      }}
      callBack={cb}
    />
  );
};
