import { Box, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PlayerWrapper = styled(Box)(({ theme }) => ({
  height: "100vh",
  width: "100vw",
  position: "relative",
  marginBottom: theme.spacing(2),
  marginLeft: "calc(-50vw + 50%)",
  marginRight: "calc(-50vw + 50%)",
}));

export const VideoContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "80%",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  backgroundColor: "#000",
}));

export const TopSection = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1.5),
  background: "linear-gradient(180deg, rgba(0,0,0,0.6) 0%, transparent 100%)",
  zIndex: 2,
}));

export const BottomSection = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(1),
  background: "linear-gradient(transparent, rgba(0, 0, 0, 0.9))",
  color: "white",
  zIndex: 2,
}));

export const VideoOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  height: "100%",
  zIndex: 2,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export const VideoPlayer = styled("video")({
  maxWidth: "100%",
  maxHeight: "100%",
  width: "auto",
  height: "auto",
  objectFit: "contain",
});

export const StickyBottom = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  zIndex: 1000,
  boxShadow: theme.shadows[3],
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const UnmutePrompt = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  color: "white",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  textAlign: "center",
  zIndex: 3,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(1),
}));

export const MessageInputContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: "#fff",
  borderTop: "1px solid rgba(0, 0, 0, 0.1)",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  zIndex: 3,
}));

export const CommentsOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: theme.spacing(8),
  left: 0,
  right: 0,
  maxHeight: "25%",
  height: "25%",
  background: "transparent",
  color: "white",
  zIndex: 2,
  padding: theme.spacing(1, 2),
}));

export const CommentsScrollContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  overflowY: "auto",
  msOverflowStyle: "none", // Hide scrollbar in IE/Edge
  scrollbarWidth: "thin", // Firefox
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "rgba(0, 0, 0, 0.1)",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "rgba(255, 255, 255, 0.3)",
    borderRadius: "3px",
  },
}));
