import { LegacyRef, useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import _ from "lodash";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import CloseIcon from "@mui/icons-material/Close";

import { BASE_URL } from "../../config";
import { Comment } from "../types";
import useInterval from "../hooks/useInterval";
import { CommentContext } from "../../store/CommentContext";

export const CommentCard = ({ comment }: { comment: Comment }) => {
  return (
    <Grid
      container
      flexDirection="row"
      justifyContent="flex-start"
      style={{
        padding: "4px 6px",
        borderRadius: "4px",
        background: "rgba(152, 152, 152, .25)",
      }}
    >
      <Grid item style={{ width: "18px" }}>
        <img
          style={{
            width: "32px",
            height: "32px",
            borderRadius: "50%",
          }}
          src={
            _.isEmpty(comment?.user?.storeBannerUrl)
              ? BASE_URL + "/images/thumbnail-default-no-image.jpg"
              : comment?.user?.storeBannerUrl
          }
          alt={
            (comment.user.firstName || "").charAt(0) +
            (comment.user.lastName || "").charAt(0)
          }
        />
      </Grid>
      <Grid
        item
        container
        flexDirection="column"
        style={{
          textAlign: "start",
          paddingLeft: "24px",
        }}
        md={10}
        xs={10}
      >
        <Grid
          item
          style={{
            color: "#FFFFFF",
            fontSize: "12px",
            fontWeight: 600,
          }}
        >
          {comment?.user?.firstName + comment?.user?.lastName}
        </Grid>
        <Grid
          item
          style={{
            color: "#FFFFFF",
            fontSize: "10px",
            width: "fit-content",
          }}
        >
          {comment?.content}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const CommentsContainer = () => {
  const [searchParams] = useSearchParams();
  const { setCommentList, commentList } = useContext(CommentContext);
  const [showComments, setShowComments] = useState(false);

  const streamId = searchParams.get("streamId");
  const scheduleStatus = parseInt(searchParams.get("status") || "");

  const getComments = async () => {
    try {
      const requestOptions = {
        method: "GET",
      };

      const response = await fetch(
        `${BASE_URL}/users/schedule/${streamId}/comments`,
        requestOptions
      );
      const data = await response.json();
      setCommentList(data);
    } catch (error) {
      console.log("Error in fetching data");
      console.log(error);
    }
  };

  useInterval(() => {
    if (!streamId) {
      return;
    }
    getComments();
  }, 5000);

  useEffect(() => {
    setShowComments(scheduleStatus === 1);
  }, [scheduleStatus]);

  const bottomRef = useRef<{
    scrollIntoView: ({ behavior }: { behavior: string }) => void;
  }>(null);

  useEffect(() => {
    console.log("scrolling to bottom");
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [commentList.length]);

  if (scheduleStatus === 0 || isNaN(scheduleStatus)) {
    return null;
  }

  return (
    <Grid container flexDirection="column" spacing={1}>
      {scheduleStatus !== 1 ? (
        <>
          {showComments ? (
            <CloseIcon
              style={{
                color: "white",
                paddingLeft: "8px",
                height: "36px",
                position: "fixed",
                marginTop: "-20px",
              }}
              onClick={() => setShowComments(!showComments)}
            />
          ) : (
            <ChatBubbleOutlineIcon
              style={{ color: "white", paddingLeft: "8px", height: "36px" }}
              onClick={() => setShowComments(!showComments)}
            />
          )}
        </>
      ) : null}

      {showComments
        ? _.map(commentList, (comment) => (
            <Grid
              item
              key={comment._id}
              style={{
                width: "100%",
              }}
            >
              <div ref={bottomRef as LegacyRef<HTMLDivElement>} />
              <CommentCard comment={comment} />
            </Grid>
          ))
        : null}
    </Grid>
  );
};
