import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import {
  BASE_URL,
  // DEEP_LINK_URL
} from "../../config";
import { Product, User } from "../types";
import { UiContext } from "../../store/UiContext";
import { CartModal } from "./CartModal";
import { getItemDetailsById } from "../utils";

export const ProductContainer = ({ store }: { store?: User }) => {
  const [searchParams] = useSearchParams();
  const streamId = searchParams.get("streamId");
  const retailerDomain = searchParams.get("retailerDomain");

  const [product, setProduct] = useState<Product>();
  const [isLoading, setIsLoading] = useState(false);
  const { setShowCartModal, setProductInfo } = useContext(UiContext);

  useEffect(() => {
    if (!streamId) {
      return;
    }

    const getProducts = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${BASE_URL}/shops/${streamId}/items`);
        const data = await response.json();
        const productDetails = await getItemDetailsById(
          data.items[0].id,
          retailerDomain || "",
        );
        setProduct(data.items[0]); // TODO: should utilize the context
        setProductInfo(productDetails);
      } catch (error) {
        console.log("Error in fetching data");
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getProducts();
  }, [streamId]);

  return isLoading || product ? (
    <div
      style={{
        background: "#F8F8F8",
        width: "100%",
        height: "100%",
      }}
    >
      <Grid
        container
        flexDirection="column"
        style={{
          maxWidth: "160px",
          textAlign: "left",
          padding: "8px",
          background: "#FFFFFF",
          border: "8px",
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid item>
              <img width="140px" src={product?.imagePath} alt={product?.name} />
            </Grid>
            <Grid
              item
              style={{
                color: "#2E2E2E",
                fontSize: "14px",
                fontWeight: 700,
              }}
            >
              {product?.name}
            </Grid>
            <Grid
              item
              style={{
                color: "#7D0779",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              ${product?.price}
            </Grid>
            <Grid item textAlign="start">
              <button
                style={{
                  color: "#FFF",
                  background: "#7D0779",
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: 600,
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (product?.type === "EXTERNAL_PRODUCT_TYPE") {
                    window.open(product?.externalProductLink, "_blank");
                    return;
                  }
                  setShowCartModal(true);
                  // open modal
                  // window.location.href = `${DEEP_LINK_URL}?screenName=ProductDetail&screenData=${product?.id}`;
                }}
              >
                Buy Now
              </button>
            </Grid>
          </>
        )}
      </Grid>
      {/* <CartModal product={product} /> */}
    </div>
  ) : null;
};
