import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { RTCPublisher } from "red5pro-webrtc-sdk";
import ReplayIcon from "@mui/icons-material/Replay";
import { useSearchParams } from "react-router-dom";

import { ProductContainer } from "../shared/components/ProductContainer";
import { CommentsContainer } from "../shared/components/CommentsContainer";
import { CommentsInput } from "../shared/components/CommentsInput";
import { StoreDetails } from "../shared/components/StoreDetails";
import { StreamHeader } from "../shared/components/StreamHeader";
import { startStream, stopStream } from "../shared/utils";
import { commentContainerStyle } from "../shared/styles";

const PublishStream = forwardRef((props, ref) => {
  const [isStarted, setIsStarted] = useState(false);
  const publisher = new RTCPublisher();
  const [searchParams] = useSearchParams();
  const streamName = searchParams.get("streamName");

  useImperativeHandle(ref, () => ({
    retryStream: start,
  }));

  const start = async () => {
    try {
      const userId = searchParams.get("userId") || "";
      const streamId = searchParams.get("streamId") || "";
      const jwtToken = searchParams.get("jwtToken") || "";
      await startStream(userId, streamId, jwtToken);
      await publisher.init({
        protocol: "wss",
        port: 443,
        host: "red5.marketplace0.live",
        app: "live",
        streamName: streamName,
        rtcConfiguration: {
          iceServers: [{ urls: "stun:stun2.l.google.com:19302" }],
          iceCandidatePoolSize: 2,
          bundlePolicy: "max-bundle",
        }, // See https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/RTCPeerConnection#RTCConfiguration_dictionary
        streamMode: "record",
        mediaElementId: "red5pro-publisher",
        bandwidth: {
          audio: 56,
          video: 512,
        },
        clearMediaOnUnpublish: true,
        mediaConstraints: {
          audio: true,
          video: {
            width: {
              exact: 640,
            },
            height: {
              exact: 480,
            },
            frameRate: {
              min: 8,
              max: 24,
            },
          },
        },
      });
      await publisher.publish();
    } catch (e) {
      // An error occoured in establishing a broadcast session.
      console.log("Error in publishing the stream");
      console.log(e);
    } finally {
      console.log("Submitted");
    }
  };

  // const stop = async () => {
  //   await publisher.unpublish();
  // };

  const [stream, setStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    const onBeforeUnload = async (ev: any) => {
      const userId = searchParams.get("userId") || "";
      const streamId = searchParams.get("streamId") || "";

      if (!userId || !streamId) {
        return;
      }

      await stopStream(userId, streamId);
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!streamName) {
      return;
    }

    setIsStarted(true);
  }, [isStarted, streamName]);

  useEffect(() => {
    if (!streamName) {
      return;
    }

    if (!isStarted) {
      return;
    }

    const enableCamera = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        setStream(mediaStream);
        setTimeout(() => {
          console.log("Starting streaming");
          start();
        }, 3000);
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    };

    if (isStarted) {
      enableCamera();
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [isStarted]);

  return (
    <div>
      {stream && (
        <div>
          <video
            style={{
              width: "100%",
            }}
            id="red5pro-publisher"
            controls
            autoPlay
            playsInline
          ></video>
        </div>
      )}
    </div>
  );
});

export const Publish: React.FC = () => {
  const streamRef = useRef<{
    retryStream: () => void;
  }>(null);

  const retryStream = () => {
    if (streamRef?.current?.retryStream) {
      streamRef?.current?.retryStream();
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
        }}
      >
        <StreamHeader />
        <ReplayIcon
          style={{
            paddingLeft: "4px",
            paddingTop: "2px",
          }}
          fontSize="small"
          onClick={retryStream}
        />
      </div>
      <div
        style={{
          position: "relative",
        }}
      >
        <PublishStream ref={streamRef} />
        <div style={commentContainerStyle}>
          <CommentsContainer />
        </div>
      </div>
      <div style={{}}>
        <StoreDetails />
      </div>
      <div
        style={{
          paddingTop: "10px",
        }}
      >
        <CommentsInput />
      </div>
      <ProductContainer />
    </div>
  );
};
