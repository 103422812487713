import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import { useContext, useEffect } from "react";
import LockIcon from "@mui/icons-material/Lock";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { UiContext } from "../../store/UiContext";
import { User } from "../types";
import { SquareProduct } from "../types/SquareUpProduct";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export const CartModal = ({
  product,
  retailer,
  consumer,
  jwtToken,
}: {
  product: SquareProduct;
  retailer: User;
  consumer: User;
  jwtToken: string;
}) => {
  const price = _.get(
    _.first(product?.item_data?.variations),
    "item_variation_data.price_money.amount",
  );
  const navigate = useNavigate();

  const {
    setShowCartModal,
    showCartModal,
    productQuantity,
    setProductQuantity,
    storeInfo,
    setStoreInfo,
    setProductInfo,
  } = useContext(UiContext);

  useEffect(() => {
    setProductInfo(product);
  }, [product]);

  const buyNow = () => {
    const userId = consumer.id;
    setShowCartModal(false);
    navigate(`/checkout?userId=${userId}&jwtToken=${jwtToken}`);
  };

  const handleClose = () => {
    setShowCartModal(false);
  };

  setStoreInfo(retailer);

  return (
    <Modal open={showCartModal} onClose={handleClose}>
      <Box sx={style}>
        <Grid
          container
          flexDirection="column"
          style={{
            backgroundColor: "#F8F8F8",
            padding: "16px",
          }}
          spacing={2}
        >
          <Grid
            item
            style={{
              color: "",
              fontSize: "12px",
            }}
          >
            {storeInfo?.storeName}
          </Grid>
          <Grid
            item
            style={{
              color: "#2E2E2E",
              fontSize: "19px",
              fontWeight: "600",
            }}
          >
            {product?.name}
          </Grid>
          <Grid
            item
            style={{
              color: "#7D0779",
              fontSize: 17,
            }}
          >
            ${price ? Math.floor(price / 100) : "N/A"}
          </Grid>
          <Grid item>
            <img
              style={{
                width: "100%",
              }}
              src={product?.imagePath}
              alt={product?.name}
            />
          </Grid>
          <Grid
            item
            style={{
              fontSize: 14,
              fontWeight: "500",
            }}
          >
            Quantity
          </Grid>
          <Grid item>
            <TextField
              variant="outlined"
              color="secondary"
              style={{
                width: "100%",
                borderColor: "#7D0779",
              }}
              type="number"
              value={productQuantity}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setProductQuantity(parseInt(event.target.value));
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#7D0779",
                width: "100%",
              }}
              onClick={buyNow}
            >
              Buy Now
            </Button>
          </Grid>
          <Grid
            item
            style={{
              color: "#2E2E2E",
              fontSize: 12,
            }}
            container
            flexDirection="row"
          >
            <div>
              <LockIcon
                style={{
                  fontSize: 12,
                  paddingRight: 4,
                }}
              />
            </div>
            Secured Payment
          </Grid>
          <Grid
            item
            container
            style={{
              color: "#2E2E2E",
              fontSize: 12,
            }}
            flexDirection="row"
          >
            <div>
              <HelpCenterIcon
                style={{
                  fontSize: 12,
                  paddingRight: 4,
                }}
              />
            </div>
            Usually delivered in 3 days
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
