import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useSearchParams } from "react-router-dom";

import { formatDate } from "../utils";

export const StreamHeader = () => {
  const [searchParams] = useSearchParams();

  const streamName = searchParams.get("streamName");
  const startTime = searchParams.get("startTime");
  const showPreview = searchParams.get("showPreview") === "true" || false;

  return (
    <div
      style={{
        color: "#2E2E2E",
        fontSize: "20px",
        textAlign: "left",
        display: "flex",
      }}
    >
      {showPreview ? (
        `Stream is scheduled at ${formatDate(startTime || "")}`
      ) : streamName ? (
        <>
          <FiberManualRecordIcon
            style={{
              color: "red",
            }}
          />{" "}
          <div
            style={{
              paddingBottom: "8px",
              paddingLeft: "4px",
            }}
          >
            Live
          </div>
        </>
      ) : (
        <div
          style={{
            paddingBottom: "8px",
            paddingLeft: "4px",
          }}
        >
          Recorded At - {formatDate(startTime || "")}
        </div>
      )}
    </div>
  );
};
