import { useContext, useEffect, useRef, useState } from "react";
import { Button, Grid, LinearProgress, Snackbar } from "@mui/material";
import { TextField } from "formik-mui";
import { Formik, Form, Field } from "formik";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { APP_ID } from "../../config";
import { GroupHeader } from "../../Pages/Checkout";
import { UiContext } from "../../store/UiContext";
import { PaymentConfirmationDialog } from "./PaymentConfirmationDialog";

interface Values {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  state: string;
  addressLine1: string;
  addressLine2: string;
  cityOrTown: string;
  zipCode: string;
  email: string;
}

export const SquareUpPayment = ({
  buyerInfo,
  sellerInfo,
  productInfo,
  callBack,
}: {
  buyerInfo: any;
  sellerInfo: any;
  productInfo: { title: string; amount: number; imageUrl: string };
  callBack: (paymentToken: string) => Promise<boolean>;
}) => {
  const { setShowPaymentConfirmationDialog } = useContext(UiContext);

  const [showSnackbar, setShowSnackbar] = useState(false);

  const summaryStyle = {
    color: "#2E2E2E",
    fontSize: "15px",
  };

  const [paymentCard, setPaymentCard] = useState<any>();
  const ref = useRef(null);

  const initiatePayment = async () => {
    const payment = window?.Square?.payments(
      APP_ID,
      sellerInfo.locationId,
    ) as any;
    const paymentCard = await payment?.card();
    await paymentCard.attach("#card-container");
    setPaymentCard(paymentCard);
  };

  const openSnackbar = () => {
    setShowSnackbar(true);
  };

  const closeSnackbar = () => {
    setShowSnackbar(false);
  };

  useEffect(() => {
    if (sellerInfo && sellerInfo.locationId) {
      initiatePayment();
    }
  }, [sellerInfo]);

  const validateForm = (values: Partial<Values>) => {
    const errors: Partial<Values> = {};

    if (!values.firstName) {
      errors.firstName = "Required";
    }

    if (!values.lastName) {
      errors.lastName = "Required";
    }

    if (!values.addressLine1) {
      errors.addressLine1 = "Required";
    }

    if (!values.addressLine2) {
      errors.addressLine2 = "Required";
    }

    if (!values.cityOrTown) {
      errors.cityOrTown = "Required";
    }

    if (!values.zipCode) {
      errors.zipCode = "Required";
    }

    if (!values.state) {
      errors.state = "Required";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.phoneNumber) {
      errors.phoneNumber = "Required";
    }

    return errors;
  };

  const ActionComponent = (
    <>
      <Button color="secondary" size="small" onClick={closeSnackbar}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Grid container flexDirection="column">
      <PaymentConfirmationDialog />
      {buyerInfo ? (
        <Formik
          initialValues={{
            firstName: buyerInfo?.firstName,
            lastName: buyerInfo?.lastName,
            phoneNumber: buyerInfo?.phoneNumber,
            state: buyerInfo?.state,
            addressLine1: buyerInfo?.addressLine1,
            addressLine2: buyerInfo?.addressLine1,
            cityOrTown: buyerInfo?.cityOrTown,
            zipCode: buyerInfo?.zipCode,
            email: buyerInfo?.email,
          }}
          validate={validateForm}
          onSubmit={async (values, { setSubmitting }) => {
            if (!paymentCard) {
              return;
            }

            try {
              const tokenResult = await paymentCard.tokenize();
              if (tokenResult.status !== "OK") {
                console.log(tokenResult.status);
                return;
              }
              setSubmitting(true);

              // TODO: Dynamic Callback
              // await handlePayment
              // await moveToPlayer()
              // const isPaymentDone = await handlePayment(values);
              // if (isPaymentDone) {
              //   // now fetch the video url
              //   // await moveToPlayer();
              // } else {
              //   openSnackbar();
              // }
              const res = await callBack(tokenResult.token);
              if (res) {
                setShowPaymentConfirmationDialog(true);
              } else {
                throw new Error("Error in making payment");
              }
            } catch (error) {
              openSnackbar();
              console.log("Error in order");
              console.log(error);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Grid container spacing={2}>
                <GroupHeader header="Shipping Information" />
                <Grid
                  item
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Field
                    component={TextField}
                    label="First Name"
                    name="firstName"
                    type="text"
                    size="small"
                    color="secondary"
                    style={{
                      width: "49%",
                    }}
                  />
                  <Field
                    component={TextField}
                    label="Last Name"
                    name="lastName"
                    type="text"
                    size="small"
                    color="secondary"
                    style={{
                      width: "49%",
                    }}
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Field
                    component={TextField}
                    label="Address Line 1"
                    name="addressLine1"
                    type="text"
                    size="small"
                    color="secondary"
                    fullWidth
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Field
                    component={TextField}
                    label="Address Line 2"
                    name="addressLine2"
                    type="text"
                    size="small"
                    color="secondary"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Field
                    component={TextField}
                    label="City"
                    name="cityOrTown"
                    type="text"
                    size="small"
                    color="secondary"
                    style={{
                      width: "49%",
                    }}
                  />
                  <Field
                    component={TextField}
                    label="Zip"
                    name="zipCode"
                    type="text"
                    size="small"
                    color="secondary"
                    style={{
                      width: "49%",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Field
                    component={TextField}
                    label="State"
                    name="state"
                    type="text"
                    size="small"
                    color="secondary"
                    style={{
                      width: "49%",
                    }}
                  />
                  <Field
                    component={TextField}
                    label="Country"
                    name="country"
                    type="text"
                    size="small"
                    color="secondary"
                    style={{
                      width: "49%",
                    }}
                  />
                </Grid>
                <GroupHeader header="Contact Information" />
                <Grid item style={{ width: "100%" }}>
                  <Field
                    component={TextField}
                    label="Email"
                    name="email"
                    type="email"
                    size="small"
                    color="secondary"
                    fullWidth
                  />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Field
                    component={TextField}
                    label="Phone No."
                    name="phoneNumber"
                    type="text"
                    size="small"
                    color="secondary"
                    fullWidth
                  />
                </Grid>
                <GroupHeader header="Order Summary" />
                <Grid item container style={{ width: "100%" }}>
                  <Grid item container justifyContent="space-between">
                    <Grid item container justifyContent="space-between">
                      <Grid item style={summaryStyle} xs={8} md={10}>
                        <Grid container flexDirection="column">
                          <Grid item overflow="hidden">
                            <img
                              style={{ width: "100%" }}
                              src={productInfo.imageUrl}
                              alt="thumbnail"
                            />
                          </Grid>
                          <Grid
                            item
                            style={{
                              color: "#0F0F0F",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {productInfo.title}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        alignItems="center"
                        style={{
                          display: "flex",
                          color: "#7D0779",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        $ {productInfo.amount.toFixed(2)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <GroupHeader header="Payment Information" />
                <Grid item style={{ width: "100%" }}>
                  <div ref={ref} id="card-container"></div>
                </Grid>
              </Grid>
              {isSubmitting && <LinearProgress color="secondary" />}
              <Button
                variant="contained"
                color="secondary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      ) : null}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        message="Something went wrong!"
        action={ActionComponent}
      />
    </Grid>
  );
};
