import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { RTCPublisher, RTCSubscriber } from "red5pro-webrtc-sdk";
import videojs from "video.js";
import Player from "video.js/dist/types/player";

import {
  getItemsByStreamId,
  getScheduleById,
  getStreamName,
  getUserDetails,
  getUserInfoByJWT,
} from "../../../shared/utils";
import { Stream, User } from "../../../shared/types";

export const useSubscribeStream = (schedule: Stream, retailer: User) => {
  const [showUnmutePrompt, setShowUnmutePrompt] = useState(true);
  const [isMuted, setIsMuted] = useState(true);

  const handleUnmute = () => {
    const videoElement = document.getElementById("red5pro-subscriber");
    if (videoElement instanceof HTMLVideoElement) {
      videoElement.muted = false;
      setIsMuted(false);
      setShowUnmutePrompt(false);
    }
  };

  const subscribeStream = async () => {
    const subscriber = new RTCSubscriber();

    const streamName = getStreamName({
      domain: retailer.domain ?? "",
      sessionId: schedule.id,
    });

    try {
      await subscriber.init({
        host: "red5.marketplace0.live",
        protocol: "wss",
        port: 443,
        app: "live",
        streamName: streamName,
        // "zilanicom6134d973230bcc06af79c036",
        rtcConfiguration: {
          iceServers: [{ urls: "stun:stun2.l.google.com:19302" }],
          iceCandidatePoolSize: 2,
          bundlePolicy: "max-bundle",
        }, // See https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/RTCPeerConnection#RTCConfiguration_dictionary
        mediaElementId: "red5pro-subscriber",
        subscriptionId:
          streamName + Math.floor(Math.random() * 0x10000).toString(16),
      });
      await subscriber.subscribe();
    } catch (e) {
      // An error occured in establishing a subscriber session.
      console.log(e);
    }
  };

  useEffect(() => {
    subscribeStream();
  }, []);

  return {
    isMuted,
    showUnmutePrompt,
    handleUnmute,
  };
};

export const usePublishStream = (schedule: Stream, retailer: User) => {
  const publishStream = async () => {
    const streamName = getStreamName({
      domain: retailer.domain ?? "",
      sessionId: schedule.id,
    });

    const publisher = new RTCPublisher();
    await publisher.init({
      protocol: "wss",
      port: 443,
      host: "red5.marketplace0.live",
      app: "live",
      streamName: streamName,
      rtcConfiguration: {
        iceServers: [{ urls: "stun:stun2.l.google.com:19302" }],
        iceCandidatePoolSize: 2,
        bundlePolicy: "max-bundle",
      }, // See https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/RTCPeerConnection#RTCConfiguration_dictionary
      streamMode: "record",
      mediaElementId: "red5pro-publisher",
      bandwidth: {
        audio: 56,
        video: 512,
      },
      clearMediaOnUnpublish: true,
      mediaConstraints: {
        audio: true,
        video: {
          width: {
            exact: 640,
          },
          height: {
            exact: 480,
          },
          frameRate: {
            min: 8,
            max: 24,
          },
        },
      },
    });
    await publisher.publish();
  };

  useEffect(() => {
    publishStream();
  }, []);
};

export const usePreRecordedStream = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showUnmutePrompt, setShowUnmutePrompt] = useState(true);

  const handleUnmute = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.muted = false;
      videoElement.play().catch(console.error);
      setShowUnmutePrompt(false);
    }
  };

  useEffect(() => {
    const playVideo = async () => {
      try {
        const videoElement = videoRef.current;
        if (videoElement) {
          videoElement.muted = false; // Unmute the video
          await videoElement.play(); // Try to play
        }
      } catch (error) {
        console.log("Autoplay was prevented:", error);
        // If autoplay with sound fails, fallback to muted
        const videoElement = videoRef.current;
        if (videoElement) {
          videoElement.muted = true;
          videoElement.play().catch(console.error);
        }
      }
    };

    playVideo();

    // Optional: Add click event listener to unmute if browser blocks autoplay with sound
    const handleClick = () => {
      const videoElement = videoRef.current;
      if (videoElement && videoElement.muted) {
        videoElement.muted = false;
        videoElement.play().catch(console.error);
      }
    };

    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    // Ensure video always plays
    const ensurePlaying = () => {
      if (video.paused) {
        video.play().catch(() => {
          video.muted = true;
          video.play().catch(console.error);
        });
      }
    };

    video.addEventListener("pause", ensurePlaying);
    return () => video.removeEventListener("pause", ensurePlaying);
  }, []);

  return {
    handleUnmute,
    showUnmutePrompt,
    videoRef,
  };
};

export const useRecordedStream = (schedule: Stream, retailer: User) => {
  const [showUnmutePrompt, setShowUnmutePrompt] = useState(true);

  const streamUrl = `https://red5.marketplace0.live/live/${getStreamName({
    domain: retailer.domain ?? "",
    sessionId: schedule.id,
  })}.m3u8`;

  const videoRef = useRef<HTMLVideoElement>(null);
  const [player, setPlayer] = useState<Player>();

  const handleUnmute = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.muted = false;
      videoElement.play().catch(console.error);
      setShowUnmutePrompt(false);
    }
  };

  useEffect(() => {
    if (videoRef.current && streamUrl) {
      const _player = videojs(videoRef.current, {
        fill: true,
        fluid: true,
        autoplay: true,
        controls: false,
        preload: "auto",
        inactivityTimeout: 0,
        sources: [
          {
            src: streamUrl,
            type: "application/x-mpegURL",
          },
        ],
      });
      setPlayer(_player);
      return () => {
        if (player) {
          player.dispose();
        }
      };
    }
  }, [streamUrl]);

  return {
    handleUnmute,
    showUnmutePrompt,
    videoRef,
  };
};

export const useMarketplaceStream = () => {
  const productDetailsRef = useRef<HTMLDivElement>(null);
  const { streamId, jwtToken, streamType } = useParams();
  const [schedule, setSchedule] = useState<Stream>();
  const [retailer, setRetailer] = useState<User>();
  const [avatarUrl, setAvatarUrl] = useState("");
  const [productInfo, setProductInfo] = useState<any>();
  const [authenticatedUser, setAuthenticatedUser] = useState<User>();

  const fetchRetailerDetails = async (userId: string, jwtToken: string) => {
    const res = await getUserDetails(userId, jwtToken);
    setRetailer(res);
    setAvatarUrl(res.storeBannerUrl ?? res.profilePictureUrl);
  };

  const fetchSchedule = async (streamId: string, jwtToken: string) => {
    const res = await getScheduleById(streamId, jwtToken);
    setSchedule(res);
  };

  const fetchAuthenticatedUser = async (jwtToken: string) => {
    const res = await getUserInfoByJWT(jwtToken);
    setAuthenticatedUser(res);
    return res;
  };

  const fetchProductDetails = async (
    retailerId: string,
    streamId: string,
    jwtToken: string,
  ) => {
    const res = await getItemsByStreamId(retailerId, streamId, jwtToken);
    setProductInfo(_.first(res.items));
  };

  const handleVideoTouch = (e: any) => {
    e.preventDefault(); // prevents default behavior
    e.stopPropagation();
  };

  const scrollToProductDetails = () => {
    productDetailsRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleCommentScroller = (e: any) => {
    e.stopPropagation();
    const element = e.currentTarget;
    const atBottom =
      element.scrollHeight - element.scrollTop === element.clientHeight;
    const atTop = element.scrollTop === 0;

    // Prevent page scroll only when scrolling within bounds
    if (
      !(atBottom && e.deltaY > 0) && // not at bottom scrolling down
      !(atTop && e.deltaY < 0) // not at top scrolling up
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (streamId && jwtToken) {
      fetchSchedule(streamId, jwtToken);
      fetchAuthenticatedUser(jwtToken);
    }
  }, [streamId, jwtToken]);

  useEffect(() => {
    if (_.isEmpty(schedule)) {
      return;
    }
    fetchRetailerDetails(_.get(schedule, "retailerId", ""), jwtToken!);
  }, [schedule, jwtToken]);

  useEffect(() => {
    if (_.isEmpty(streamId) || _.isEmpty(jwtToken) || _.isEmpty(retailer)) {
      return;
    }
    fetchProductDetails(_.get(retailer, "id", ""), streamId!, jwtToken!);
  }, [streamId, jwtToken, retailer]);

  return {
    schedule,
    retailer,
    avatarUrl,
    authenticatedUser,
    productInfo,
    streamType,
    handleVideoTouch,
    handleCommentScroller,
    scrollToProductDetails,
    productDetailsRef,
    jwtToken,
  };
};
