import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.css";
import { Publish } from "./Pages/Publish";
import { Subscribe } from "./Pages/Subscribe";
import { UiProvider } from "./store/UiContext";
import { CommentProvider } from "./store/CommentContext";
import { Checkout } from "./Pages/Checkout";
import { PaymentStream } from "./Pages/PaymentStream";
import { PaymentProject } from "./Pages/PaymentProject";
import { MarketplaceStream } from "./Pages/MarketPlaceStream/MarketplaceStream";

const router = createBrowserRouter([
  {
    path: "/publish",
    element: <Publish />,
  },
  {
    path: "/subscribe",
    element: <Subscribe />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },
  {
    path: "/payment/stream",
    element: <PaymentStream />,
  },
  {
    path: "/payment/project",
    element: <PaymentProject />,
  },
  {
    path: "/web-view/:streamId/:jwtToken/:streamType",
    element: <MarketplaceStream />,
  },
]);

function App() {
  return (
    <div className="App" style={{}}>
      {/* <button onClick={() => {
        window.location.replace('exp://10.0.2.2:19000?screen=ProductDetail"');
      }}>Go to Product details</button>
      <button onClick={() => {
        window.location.replace('marketplace0://?screen=ProductDetail');
      }}>Go to Product details with scheme: marketplace0</button>
      Publish a Stream */}
      <UiProvider>
        <CommentProvider>
          <RouterProvider router={router} />
        </CommentProvider>
      </UiProvider>
    </div>
  );
}

export default App;
