import { Box, Typography, Avatar } from "@mui/material";
import moment from "moment";
import _ from "lodash";

interface ProductSummaryProps {
  onViewProduct?: () => void;
  streamName: string;
  scheduleStartTime: string;
  streamUrl: string;
}

export const StreamSummary = ({
  onViewProduct,
  streamName,
  scheduleStartTime,
  streamUrl,
}: ProductSummaryProps) => {
  // console.log(JSON.stringify(productInfo, null, 2));
  // const imageUrl = _.get(_.first(productInfo?.images), "object.image_data.url");
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <Avatar
          variant="rounded"
          src={streamUrl}
          sx={{ width: 48, height: 48 }}
        />
        <Box>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {streamName}
          </Typography>
          <Typography variant="body2" sx={{ opacity: 0.7 }}>
            Started at • {moment(scheduleStartTime).format("D MMM")}
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="body2"
        sx={{
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={onViewProduct}
      >
        View Product
      </Typography>
    </Box>
  );
};
