import React, { createContext, useState } from "react";

import { Comment } from "../shared/types";

interface CommentContextProps {
  commentList: Array<Comment>;
  setCommentList: (commentList: Array<Comment>) => void;
}

export const CommentContext = createContext<CommentContextProps>({
  commentList: [],
  setCommentList: (commentList: Array<Comment>) => {},
});

export const CommentProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { Provider } = CommentContext;
  const [commentList, setCommentList] = useState<Array<Comment>>([]);

  return (
    <Provider
      value={{
        commentList,
        setCommentList,
      }}
    >
      {children}
    </Provider>
  );
};
