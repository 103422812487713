import { Box, Typography, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import _ from "lodash";

import { getItemExpandedDetailsById } from "../../utils";

const ProductShowcase = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  marginTop: theme.spacing(2),
  borderRadius: theme.spacing(1),
}));

const ProductCard = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "flex-start",
  marginBottom: theme.spacing(1),
}));

const ReadMoreLink = styled(Typography)(({ theme }) => ({
  color: "#6200ee",
  cursor: "pointer",
  textDecoration: "none",
}));

export const ProductDetails = ({ productInfo }: { productInfo: any }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  return (
    <Box sx={{ px: 2, maxWidth: "lg", mx: "auto", pb: 20 }}>
      <ProductShowcase>
        <ProductCard>
          <Avatar
            variant="rounded"
            src={_.get(productInfo, "imagePath")}
            sx={{
              width: 80,
              height: 60,
              borderRadius: 1,
            }}
          >
            P
          </Avatar>
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2" sx={{ mb: 0.5 }}>
              {_.get(productInfo, "name")}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                mb: 0.5,
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }}
            >
              {isTruncated
                ? _.truncate(_.get(productInfo, "description"), {
                    length: 200,
                  })
                : _.get(productInfo, "description")}
            </Typography>
            {isTruncated ? (
              <ReadMoreLink
                variant="body2"
                onClick={() => setIsTruncated(!isTruncated)}
              >
                Read more
              </ReadMoreLink>
            ) : null}
          </Box>
        </ProductCard>
      </ProductShowcase>
    </Box>
  );
};
