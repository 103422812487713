import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { RTCSubscriber } from "red5pro-webrtc-sdk";
import _ from "lodash";

import { OfflineVideoPlayer } from "../shared/components/Video";
import { CommentsContainer } from "../shared/components/CommentsContainer";
import { ProductContainer } from "../shared/components/ProductContainer";
import { CommentsInput } from "../shared/components/CommentsInput";
import { StoreDetails } from "../shared/components/StoreDetails";
import { commentContainerStyle } from "../shared/styles";

export const DisplayStream = ({ streamName }: { streamName: string }) => {
  const start = async () => {
    const subscriber = new RTCSubscriber();

    try {
      await subscriber.init({
        host: "red5.marketplace0.live",
        protocol: "wss",
        port: 443,
        app: "live",
        streamName: streamName,
        // "zilanicom6134d973230bcc06af79c036",
        rtcConfiguration: {
          iceServers: [{ urls: "stun:stun2.l.google.com:19302" }],
          iceCandidatePoolSize: 2,
          bundlePolicy: "max-bundle"
        }, // See https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/RTCPeerConnection#RTCConfiguration_dictionary
        mediaElementId: "red5pro-subscriber",
        subscriptionId:
          streamName + Math.floor(Math.random() * 0x10000).toString(16)
      });
      await subscriber.subscribe();
    } catch (e) {
      // An error occured in establishing a subscriber session.
      console.log(e);
    }
  };

  useEffect(() => {
    if (!streamName) {
      return;
    }
    start();
  }, [streamName]);

  return (
    <div>
      <video
        id='red5pro-subscriber'
        controls
        autoPlay
        muted
        playsInline
        style={{
          width: "100%"
        }}
      ></video>
    </div>
  );
};

export const DisplayPreview = () => {
  const [searchParams] = useSearchParams();
  const streamThumbnailUrl = searchParams.get("streamThumbnailUrl") || "";

  return (
    <img
      src={streamThumbnailUrl}
      style={{ width: "100%" }}
      alt='stream-thumbnail'
    />
  );
};

const Player = () => {
  const [searchParams] = useSearchParams();
  const streamUrl = searchParams.get("streamUrl") || "";
  const streamName = searchParams.get("streamName") || "";
  const showPreview = searchParams.get("showPreview") === "true" || false;
  const showRecorded = searchParams.get("showRecorded") === "true" || false;
  const advertizeVideoUrl = searchParams.get("advertizeVideoUrl") || "";

  const onlinePlayer = _.size(streamName) ? true : false;

  if (showRecorded && _.size(advertizeVideoUrl)) {
    return (
      <video
        controls
        autoPlay
        muted
        playsInline
        loop
        style={{
          width: "100%"
        }}
      >
        <source src={advertizeVideoUrl}></source>
      </video>
    );
  }

  if (showPreview) {
    return <DisplayPreview />;
  }

  if (onlinePlayer) {
    return <DisplayStream streamName={streamName} />;
  }

  return <OfflineVideoPlayer streamUrl={streamUrl} />;
};

export const Subscribe = () => {
  const [searchParams] = useSearchParams();
  const streamName = searchParams.get("streamName") || "";
  const showPreview = searchParams.get("showPreview") === "true" || false;

  const onlinePlayer = _.size(streamName) ? true : false;

  return (
    <div>
      {/* <StreamHeader /> */}
      <div>
        <StoreDetails />
      </div>
      <div
        style={{
          position: "relative"
        }}
      >
        <Player />
        <div style={commentContainerStyle}>
          <CommentsContainer />
        </div>
      </div>
      <div
        style={{
          paddingTop: "10px"
        }}
      >
        <CommentsInput disabled={!onlinePlayer || showPreview} />
      </div>
      <ProductContainer />
    </div>
  );
};
