import { Box, Typography, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext, useEffect, useRef } from "react";
import _ from "lodash";

import useInterval from "../../hooks/useInterval";
import { getCommentsByStreamId } from "../../utils";
import { CommentContext } from "../../../store/CommentContext";

const CommentsWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxHeight: "100%",
  overflowY: "auto",
  scrollBehavior: "smooth",
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "rgba(0, 0, 0, 0.1)",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "rgba(255, 255, 255, 0.3)",
    borderRadius: "3px",
  },
});

const Comment = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(1),
  backgroundColor: "rgba(0, 0, 0, 0.15)",
  borderRadius: "16px",
  backdropFilter: "blur(2px)",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
}));

export const CommentsContainer = ({ streamId }: { streamId: string }) => {
  const { setCommentList, commentList } = useContext(CommentContext);
  const commentsEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    commentsEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Scroll to bottom when comments change
  useEffect(() => {
    // scrollToBottom();
  }, [commentList]);

  const fetchComments = async () => {
    const res = await getCommentsByStreamId(streamId);
    setCommentList(res);
  };

  const getCommenterName = ({
    firstName,
    lastName,
    storeName,
  }: {
    firstName: string;
    lastName: string;
    storeName: string;
  }) => {
    if (!_.isEmpty(storeName)) {
      return storeName;
    }

    if (!_.isEmpty(firstName + lastName)) {
      return `${firstName} ${lastName}`;
    }

    return "User";
  };

  useInterval(() => {
    if (!streamId) {
      return;
    }
    fetchComments();
  }, 5000);

  return (
    <CommentsWrapper>
      {_.map(_.reverse(commentList), ({ _id, content, user }) => (
        <Comment key={_id}>
          <Box display="flex" gap={1} alignItems="center">
            <Avatar
              src={
                _.get(user, "profilePictureUrl") ??
                _.get(user, "storeBannerUrl")
              }
              sx={{ width: 24, height: 24 }}
            />
            <Box>
              <Typography
                variant="caption"
                sx={{
                  color: "rgba(255, 255, 255, 0.9)",
                  fontWeight: "500",
                }}
              >
                {getCommenterName(user)}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "rgba(255, 255, 255, 0.95)",
                  fontWeight: "400",
                }}
              >
                {content}
              </Typography>
            </Box>
          </Box>
        </Comment>
      ))}
      {/* Invisible div at the bottom to scroll to */}
      <div ref={commentsEndRef} />
    </CommentsWrapper>
  );
};
